import { all } from 'redux-saga/effects'
import authen from './Authentication'
import login from './Login'
import logout from './Logout'
import snsList from './SNSList'
import product from './Product'
import dashBoard from './DashBoard'
import clinicList from './Clinic'
import userManagement from './UserManagement'
import packageTemplateSaga from './LicensePackageTemplate'
import releaseListSaga from './ReleaseManagement'
import releaseNoteSage from './ReleaseNote'
import notificationManagementSage from './NotificationManagement'
import autoDownloadManagementSaga from './AutoDownloadManagement'
import salesAreaManagementSaga from './SalesAreaManagement'
import localeSettingSaga from './LocaleSetting'

function* rootSaga() {
  yield all([
    ...authen,
    ...login,
    ...logout,
    ...snsList,
    ...product,
    ...dashBoard,
    ...clinicList,
    ...userManagement,
    ...packageTemplateSaga,
    ...releaseListSaga,
    ...autoDownloadManagementSaga,
    ...salesAreaManagementSaga,
    ...releaseNoteSage,
    ...notificationManagementSage,
    ...localeSettingSaga
  ])
}

export default rootSaga
