import Environment from '../configs/environment/environment'

const env = new Environment()
export default class GraphService {
  graphUrl: any
  constructor() {
    this.graphUrl = env.apiUrl
  }

  getUserInfo = (token: any) => {
    const headers = new Headers({ Authorization: `Bearer ${token}` })
    const options = {
      headers
    }
    return fetch(`${this.graphUrl}users/roles`, options)
      .then((response) => response.json())
      .catch((response) => {
        throw new Error(response)
      })
  }

  addNewUser = (token: any, userInfo: any) => {
    const headers = new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    })
    const body = JSON.stringify(userInfo)
    const options = {
      method: 'POST',
      headers,
      body
    }
    return fetch(`${this.graphUrl}users`, options)
      .then((response) => response.json())
      .catch((error) => {
        throw new Error(error)
      })
  }
}
