export const ROLES = ['No Role', null, 'Affiliate', 'ES', 'Admin']

export const ROLES_DESCRIPTION = ['No Role', '', 'Affiliate', 'ES', 'Administrator']

const numberTwo = 2
const numberThree = 3
const numberFour = 4

export enum RoleEnum {
  User = 0,
  Affiliate = numberTwo,
  ES = numberThree,
  Admin = numberFour
}
