import { put, call } from 'redux-saga/effects'
import * as types from '../../../configs/constants/actionType'
import generateActions from '../../actions/actions'
import axios from '../../../configs/axios/axios'
import { AxiosRequestConfig } from 'axios'
const ENDPOINTS = 'locale-setting'

const getDataByPage = (page?: number, size?: number, objectParams?: object) => {
  const pageR = page !== undefined && page > 0 ? page - 1 : 0
  const config = {
    page: pageR,
    size: Number(size),
    ...objectParams
  }
  return axios.post(`${ENDPOINTS}/findAll`, config as AxiosRequestConfig).then((res) => res.data)
}

export function* localeListSagaByPage(action: any) {
  try {
    const data = yield call(() =>
      getDataByPage(action.params.page, action.params.size, action.params.objectParams).then(
        (res: any) => res
      )
    )
    yield put({
      type: generateActions(types.LOCALE_LIST).SUCCESS,
      payload: { localeList: data.data }
    })
  } catch (err) {
    yield put({ type: generateActions(types.LOCALE_LIST).FAILURE })
    Error(err)
  }
}

export function* getAllLocaleSaga() {
  try {
    const data = yield call(() => axios.get(`${ENDPOINTS}/locales`).then((res) => res.data.data))
    yield put({
      type: generateActions(types.LOCALE_ALL).SUCCESS,
      payload: { allLocales: data }
    })
  } catch (err) {
    yield put({ type: generateActions(types.LOCALE_ALL).FAILURE })
    Error(err)
  }
}

const getLocaleDetails = (id: number) => {
  return axios.get(`${ENDPOINTS}/${id}`).then((res) => res.data)
}

export function* localeDetailsSaga(action: any) {
  try {
    const data = yield call(() => getLocaleDetails(action.data).then((res: any) => res))
    yield put({
      type: generateActions(types.LOCALE_DETAILS).SUCCESS,
      payload: { localeDetails: data.data }
    })
  } catch (error) {
    Error(error)
  }
}

const updateLocale = (data: any) => {
  return axios.put(`${ENDPOINTS}/${data.id}`, data)
}

export function* updateLocaleSaga(action: any) {
  try {
    yield call(() => updateLocale(action.data).then((res: any) => res.data))
    const successResponse = {
      msg: 'Locale is updated successfully.',
      status: 'success',
      action: 'edit'
    }
    yield put({
      type: generateActions(types.UPDATE_LOCALE).SUCCESS,
      payload: { responseStatus: { ...successResponse } }
    })
  } catch (err) {
    const errResponse = {
      msg: 'Locale is NOT updated successfully.',
      status: 'error',
      action: 'edit',
      info: err.response?.data?.error?.moreInfo
    }
    yield put({
      type: generateActions(types.UPDATE_LOCALE).FAILURE,
      payload: { responseStatus: { ...errResponse } }
    })
    Error(err)
  }
}
