import { combineReducers } from 'redux'
import loginReducer from './loginReducer'
import snsListReducer from './snsListReducer'
import packageTemplateReducer from './packageTemplateReducer'
import productReducer from './productReducer'
import dashBoardReducer from './dashBoardReducer'
import clinicListReducer from './clinicReducer'
import userMnReducer from './userMnReducer'
import releaseReducer from './releaseReducer'
import autoDownloadReducer from './autoDownloadManagementReducer'
import salesAreaReducer from './salesAreaManagementReducer'
import releaseNoteReducer from './releaseNoteReducer'
import notificationReducer from './notificationReducer'
import localeReducer from './localeReducer'

const rootReducer = combineReducers({
  loginReducer,
  snsListReducer,
  packageTemplateReducer,
  productReducer,
  dashBoardReducer,
  clinicListReducer,
  userMnReducer,
  releaseReducer,
  autoDownloadReducer,
  salesAreaReducer,
  releaseNoteReducer,
  notificationReducer,
  localeReducer
})
export default rootReducer
