import * as types from '../../configs/constants/actionType'
import { parseUserInfo } from '../../utils/helper'
import generateActions from '../actions/actions'

const snsListReducer = (state: any = {}, action: any) => {
  switch (action.type) {
    case generateActions(types.SNSLIST).REQUEST:
      return Object.assign({}, state, { rows: [] }, { isLoading: true })

    case generateActions(types.SNSLIST).SUCCESS:
      return Object.assign({}, state, action.payload, { isLoading: false })

    case generateActions(types.SNSLIST).FAILURE:
      return Object.assign({}, state, { isLoading: false, count: 0, rows: [] })

    case generateActions(types.LICENSELIST).REQUEST:
      return Object.assign({}, state, { rows: [], data: null }, { isLoading: true })

    case generateActions(types.LICENSELIST).SUCCESS:
      return Object.assign({}, state, action.payload, { isLoading: false })

    case generateActions(types.LICENSELIST).FAILURE:
      return Object.assign({}, state, { isLoading: false, count: 0, rows: [] })

    case generateActions(types.PRODUCTLIST).SUCCESS:
      return Object.assign({}, state, action.payload)

    case generateActions(types.LICENSE_PACKAGES).REQUEST:
      return Object.assign({}, state, { licensePackage: null })

    case generateActions(types.LICENSE_PACKAGES).SUCCESS:
      return Object.assign({}, state, action.payload)

    case generateActions(types.LICENSE_KEY_DETAIL).SUCCESS:
      return Object.assign({}, state, action.payload)

    case generateActions(types.LICENSE_PACKAGES_STATUS).SUCCESS:
      const listKeys = action.keys as string[]
      if (state.licensePackage?.Licenses) {
        state.licensePackage.Licenses?.forEach((element: any) => {
          let tempElement = element
          if (listKeys.includes(element.key)) {
            // if (action?.keyMapData) {
            //   tempElement = action?.keyMapData[element?.key]
            // }
            tempElement.updated = new Date()
            tempElement.status = 2
            tempElement.statusDescription = 'Deactivated'
            tempElement.updatedUser = parseUserInfo()
          }
        })
      }

      if (state.licenseKeyDetail && listKeys.includes(state.licenseKeyDetail.key)) {
        if (action?.keyMapData) {
          state.licenseKeyDetail = action.keyMapData[state.licenseKeyDetail?.key]
        }
        state.licenseKeyDetail.status = 2
        state.licenseKeyDetail.statusDescription = 'Deactivated'
        state.licenseKeyDetail.updatedUser = parseUserInfo()
      }

      if (state.listLicenseByClinicID?.data) {
        state.listLicenseByClinicID?.data?.forEach((element: any) => {
          let tempElement = element
          if (listKeys.includes(element.key)) {
            tempElement.updated = new Date()
            tempElement.status = 'Deactivated'
          }
        })
      }
      return Object.assign({}, state, action.payload)
    case generateActions(types.LICENSE_PACKAGES_STATUS).FAILURE:
      return Object.assign({}, state, action.payload)

    case generateActions(types.LICENSE_PACKAGES_DATA).SUCCESS:
      const licenseKeyDetailUpdated = action.licenseKeyDetailUpdated
      const licenses = state.licensePackage?.Licenses
      if (licenses) {
        licenses?.forEach((element: any) => {
          if (licenseKeyDetailUpdated.key === element.key) {
            element.updated = licenseKeyDetailUpdated.updated
            element.updatedUser = licenseKeyDetailUpdated.updatedUser
            element.selectedMetadata = licenseKeyDetailUpdated.selectedMetadata
            element.ccu = licenseKeyDetailUpdated.ccu
            element.maxCcu = licenseKeyDetailUpdated.maxCcu
          }
        })
      }

      const listLicenseByClinicID = state.listLicenseByClinicID?.data
      if (listLicenseByClinicID) {
        listLicenseByClinicID?.forEach((element: any) => {
          if (licenseKeyDetailUpdated.key === element.key) {
            element.updated = new Date()
          }
        })
      }
      return Object.assign({}, state, action.payload)

    case generateActions(types.LICENSE_PACKAGES_DATA).FAILURE:
      return Object.assign({}, state, action.payload)

    case generateActions(types.SAP_CODE).REQUEST:
      return Object.assign({}, state, { sapCode: null })

    case generateActions(types.SAP_CODE).SUCCESS:
      return Object.assign({}, state, action.payload)

    case generateActions(types.DROPDOWN).REQUEST:
      return Object.assign({}, state, {
        sapCode: null,
        sapOrder: null,
        usbSn: null,
        machineSn: null,
        clinicName: null
      })

    case generateActions(types.DROPDOWN).SUCCESS:
      return Object.assign({}, state, action.payload)

    case generateActions(types.TEMPLATE_DETAIL).REQUEST:
      return Object.assign({}, state, { templateDetail: null, isLoadingTD: true })

    case generateActions(types.TEMPLATE_DETAIL).SUCCESS:
      return Object.assign({}, state, action.payload, { isLoadingTD: false })

    case generateActions(types.TEMPLATE_DETAIL).FAILURE:
      return Object.assign({}, state, { isLoadingTD: false })

    case types.CLEAR_TEMPLATE_DETAIL:
      return Object.assign({}, state, { templateDetail: null })

    case generateActions(types.RENEW_LK).SUCCESS:
      return Object.assign({}, state, action.payload, {
        licenseKeyDetail: { ...state?.licenseKeyDetail, ...action?.licenseKeyDetail }
      })

    case generateActions(types.RENEW_LK).FAILURE:
      return Object.assign({}, state, action.payload)

    case generateActions(types.GENERATE_LK).FAILURE:
      return Object.assign({}, state, action.payload)

    case generateActions(types.EXPORT_LK).REQUEST:
      return Object.assign({}, state, { isLoadingExport: true })

    case generateActions(types.EXPORT_LK).SUCCESS:
      return Object.assign({}, state, { isLoadingExport: false })

    case generateActions(types.EXPORT_LK).FAILURE:
      return Object.assign({}, state, { isLoadingExport: false })

    case generateActions(types.EXPORT_CSV_LK).REQUEST:
      return Object.assign({}, state, { isLoadingExport: true })

    case generateActions(types.EXPORT_CSV_LK).SUCCESS:
      return Object.assign({}, state, { isLoadingExport: false })

    case generateActions(types.EXPORT_CSV_LK).FAILURE:
      return Object.assign({}, state, { isLoadingExport: false })

    case generateActions(types.CLEAR_ERROR).REQUEST:
      return Object.assign({}, state, { responseStatus: null })

    case types.CLEAR_SELECTED:
      return Object.assign({}, state, { licenseKeyDetail: null, licensePackage: null })

    case types.CLEAR_LICENSE_BY_CLINICID:
      return Object.assign({}, state, { listLicenseByClinicID: null })

    default:
      return state
  }
}

export default snsListReducer
