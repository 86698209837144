const numberFour = 4
const numberThree = 3
const numberTwo = 2

export const tabs = {
  dashboard: [
    {
      name: 'Operational Statistics',
      link: '/dashboard',
      role: [numberFour]
    }
  ],
  'license-key-list': [
    {
      name: 'License Keys',
      link: '/license-key-list',
      role: [numberTwo, numberThree, numberFour]
    },
    {
      name: 'Clinics',
      link: '/clinic-list',
      role: [numberTwo, numberThree, numberFour]
    },
    {
      name: 'Package Templates',
      link: '/license-package-template',
      role: [numberThree, numberFour]
    }
  ],
  'release-list': [
    {
      name: 'Release List',
      link: '/release-list',
      role: [numberThree, numberFour]
    },
    {
      name: 'Release Note',
      link: '/release-note',
      role: [numberTwo, numberThree, numberFour]
    },
    {
      name: 'Notification',
      link: '/notification',
      role: [numberTwo, numberThree, numberFour]
    },
    {
      name: 'Auto-Download',
      link: '/auto-download-management',
      role: [numberThree, numberFour]
    }
  ],
  'sales-area-management': [
    {
      name: 'Sales-Area',
      link: '/sales-area-management',
      role: [numberThree, numberFour]
    },
    {
      name: 'Locale Setting',
      link: '/locale-setting',
      role: [numberThree, numberFour]
    }
  ],
  'user-management': [
    {
      name: 'Users Management',
      link: '/user-management',
      role: [numberFour]
    }
  ]
}

export const headers = {
  'user-management': tabs['user-management'],
  dashboard: tabs['dashboard'],
  'license-key-list': tabs['license-key-list'],
  'license-package-template': tabs['license-key-list'],
  'clinic-list': tabs['license-key-list'],
  'release-list': tabs['release-list'],
  'release-note': tabs['release-list'],
  notification: tabs['release-list'],
  'auto-download-management': tabs['release-list'],
  'sales-area-management': tabs['sales-area-management'],
  'locale-setting': tabs['sales-area-management']
}
