export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const CLEAR_USER_INFO = 'CLEAR_USER_INFO'
export const ADD_NEW_USER = 'ADD_NEW_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const DATA_USERS = 'DATA_USERS'
export const ROLES = 'ROLES'
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'
export const UM_CLEAR_ERROR = 'UM_CLEAR_ERROR'
export const SNSLIST = 'SNSLIST'
export const LICENSELIST = 'LICENSELIST'
export const PRODUCTLIST = 'PRODUCTLIST'
export const PRODUCTSINFO = 'PRODUCTSINFO'
export const CRYPTLEXPRODUCTS = 'CRYPTLEXPRODUCTS'
export const CRYPTLEXPOLICIES = 'CRYPTLEXPOLICIES'
export const ADD_NEW_PACKAGE_TEMPLATE = 'ADD_NEW_PACKAGE_TEMPLATE'
export const PRODUCTSINFODATA = 'PRODUCTSINFODATA'
export const GET_METADATA = 'GET_METADATA'
export const LICENSE_PACKAGES = 'LICENSE_PACKAGES'
export const LICENSE_KEY_DETAIL = 'LICENSE_KEY_DETAIL'
export const LICENSE_PACKAGES_STATUS = 'LICENSE_PACKAGES_STATUS'
export const LICENSE_PACKAGES_DATA = 'LICENSE_PACKAGES_DATA'
export const CLEAR_ERROR = 'CLEAR_ERROR'
export const CLEAR_ERROR_PRODUCT = 'CLEAR_ERROR_PRODUCT'
export const SAP_CODE = 'SAP_CODE'
export const COMPANY = 'COMPANY'
export const DROPDOWN = 'DROPDOWN'
export const EXPORT_LK = 'EXPORT_LK'
export const EXPORT_CSV_LK = 'EXPORT_CSV_LK'
export const GENERATE_LK = 'GENERATE_LK'
export const TEMPLATE_DETAIL = 'TEMPLATE_DETAIL'
// export const CLEAR_TEMPLATE_DETAIL = 'CLEAR_TEMPLATE_DETAIL';
export const RENEW_LK = 'RENEW_LK'
export const CLEAR_SELECTED = 'CLEAR_SELECTED'

// DASHBOARD
export const DAS_PRODUCT = 'DAS_PRODUCT'
export const DAS_MONTH = 'DAS_MONTH'
export const DAS_GENERATE = 'DAS_GENERATE'

export const ADD_NEW_PRODUCT = 'ADD_NEW_PRODUCT'
export const DELETE_PRODUCT = 'DELETE_PRODUCT'
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT'

export const CLINIC_LIST = 'CLINIC_LIST'
export const SCHEDULE = 'SCHEDULE'
export const UPDATE_SCHEDULE = 'UPDATE_SCHEDULE'
export const CLEAR_ERROR_CLINIC = 'CLEAR_ERROR_CLINIC'
export const SYNC_CLINIC = 'SYNC_CLINIC'

export const CLEAR_TEMPLATE_DETAIL = 'CLEAR_TEMPLATE_DETAIL'
export const CLEAR_LICENSE_BY_CLINICID = 'CLEAR_LICENSE_BY_CLINICID'

// LICENSE PACKAGE TEMPLATE
// export const LP_DETAIL = 'LP_DETAIL';
export const LP_LIST = 'LP_LIST'
export const LP_UPDATE = 'LP_UPDATE'
export const LP_DELETE = 'LP_DELETE'
export const LP_CREATE = 'LP_CREATE'

// RELEASE MANAGEMENT
export const RELEASE_LIST = 'RELEASE_LIST'
export const ADD_RELEASE = 'ADD_RELEASE'
export const RELEASE_DETAILS = 'RELEASE_DETAILS'
export const UPDATE_RELEASE = 'UPDATE_RELEASE'
export const DELETE_RELEASE = 'DELETE_RELEASE'
export const PUBLISH_RELEASE = 'PUBLISH_RELEASE'
export const VERSION_RELEASE_LIST = 'VERSION_RELEASE_LIST'
export const PRODUCT_TYPE_LIST = 'PRODUCT_TYPE_LIST'
export const VERSION_PARENT_APP = 'VERSION_PARENT_APP'
export const CLEAR_RELEASE_BY_PRODUCT = 'CLEAR_RELEASE_BY_PRODUCT'
export const VERSION_BY_PRODUCT = 'VERSION_BY_PRODUCT'
export const GET_RELEASES = 'GET_RELEASES'
export const UPLOAD_RELEASE = 'UPLOAD_RELEASE'
export const CLOSE_UPDATE_CONFIRM_MODAL = 'CLOSE_UPDATE_CONFIRM_MODAL'

// RELEASE NOTE
export const RELEASE_NOTE_LIST = 'RELEASE_NOTE_LIST'
export const ADD_RELEASE_NOTE = 'ADD_RELEASE_NOTE'
export const RELEASE_NOTE_DETAILS = 'RELEASE_NOTE_DETAILS'
export const UPDATE_RELEASE_NOTE = 'UPDATE_RELEASE_NOTE'
export const DELETE_RELEASE_NOTE = 'DELETE_RELEASE_NOTE'
export const GET_RELEASE_NOTES = 'GET_RELEASE_NOTES'
export const CLEAR_RELEASE_NOTES = 'CLEAR_RELEASE_NOTES'

// NOTIFICATION
export const NOTIFICATION_LIST = 'NOTIFICATION_LIST'
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION'
export const SELECT_COUNTRY = 'SELECT_COUNTRY'
export const NOTIFICATION_DETAILS = 'NOTIFICATION_DETAILS'
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION'
export const UPDATE_NOTIFICATION_COUNTRY = 'UPDATE_NOTIFICATION_COUNTRY'
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION'

// AUTO-DOWNLOAD MANAGEMENT
export const RC_LIST = 'RC_LIST'
export const ADD_RC = 'ADD_RC'
export const RC_DETAILS = 'RC_DETAILS'
export const UPDATE_RC = 'UPDATE_RC'
export const DELETE_RC = 'DELETE_RC'
export const VERSION_AUTO_LIST = 'VERSION_AUTO_LIST'
export const VERSION_PUBLISHED = 'VERSION_PUBLISHED'

// SALES AREA MANAGEMENT
export const AREA_LIST = 'SALES_AREA_LIST'
export const ADD_SALES_AREA = 'ADD_SALES_AREA'
export const SALES_AREA_DETAILS = 'SALES_AREA_DETAILS'
export const UPDATE_SALES_AREA = 'UPDATE_SALES_AREA'
export const DELETE_SALES_AREA = 'DELETE_SALES_AREA'
export const AREAS_NAME = 'AREAS_NAME'
export const AREAS_ALL = 'AREAS_ALL'
export const CREATOR_LIST = 'CREATOR_LIST'
export const ALLOW_LIST = 'ALLOW_LIST'
export const AREAS_CURRRENT_USER = 'AREAS_CURRRENT_USER'

// LOCALE SETTING
export const LOCALE_LIST = 'LOCALE_LIST'
export const LOCALE_DETAILS = 'LOCALE_DETAILS'
export const LOCALE_ALL = 'LOCALE_ALL'
export const UPDATE_LOCALE = 'UPDATE_LOCALE'
